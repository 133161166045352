import React from "react"
import Layout from "../layout"

import "../css/header.css"
import "../css/features.css"
import "../css/food-drink.css"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Video from "../components/Video"
import videos from "../util/videos"

export default function FoodDrink() {
  // TODO: does this load the right sizes; e.g. 100% of container or 100vw of container?
  const images = useStaticQuery(graphql`
    {
      chef: file(relativePath: { eq: "food-drink/chef@2x.jpg" }) {
        ...jpgFragment
      }
      plate: file(relativePath: { eq: "food-drink/plate@2x.jpg" }) {
        ...jpgFragment
      }
      serving: file(relativePath: { eq: "food-drink/serving@2x.jpg" }) {
        ...jpgFragment
      }
      cocktail: file(relativePath: { eq: "food-drink/cocktail@2x.jpg" }) {
        ...jpgFragment
      }
      drizzle: file(relativePath: { eq: "food-drink/drizzle@2x.jpg" }) {
        ...jpgFragment
      }
      tapas: file(relativePath: { eq: "food-drink/tapas@2x.jpg" }) {
        ...jpgFragment
      }
      bartender: file(relativePath: { eq: "food-drink/bartender@2x.jpg" }) {
        ...jpgFragment
      }
      shake: file(relativePath: { eq: "food-drink/shake.jpg" }) {
        ...jpgFragment
      }
    }
  `)

  return (
    <Layout>
      <header className="Header Header--image">
        <h1 class="stretched">
          <span>Food</span> <span>+ Drink</span>
        </h1>
        <figure class="Header__figure Header__figure--foodDrink">
          <Video playback_id={videos.foodDrink} />
        </figure>
      </header>
      <p className="Section__intro">
        Second&nbsp;Floor&nbsp;Events is proud to serve a seasonally rotating
        catering menu developed by Executive Chef Jeff Dueck. Custom menu
        options are available to perfectly match the way you’d like to feed your
        guests, including Kosher and gluten-free selections, along with an
        extensive list of local beers, wines, spirits, and cocktails to choose
        from.
      </p>
      <hr className="vr" />
      <div style={{ textAlign: "center", position: "relative", zIndex: 4 }}>
        <p>Interested in learning more about our menus?</p>
        <p>
          <Link to="/contact" className="Button">
            Get in Touch
          </Link>
        </p>
      </div>

      <div className="Gallery">
        <figure>
          <Img fluid={images.chef.childImageSharp.fluid} />
          <figcaption>Executive Chef Jeff Dueck</figcaption>
        </figure>
        <figure>
          <Img fluid={images.plate.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={images.serving.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={images.cocktail.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={images.drizzle.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={images.tapas.childImageSharp.fluid} />
        </figure>
      </div>

      <figure>
        <Img fluid={images.bartender.childImageSharp.fluid} />
      </figure>
    </Layout>
  )
}
